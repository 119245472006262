import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BtpProgressStatus } from '../../../core/models/btp-progress.model';
import { SessionService } from '../../../core/services/session.service';
import { ToastService } from '../../../core/services/toast.service';
import { UxService } from '../../../core/services/ux.service';
import { mockJobResponse } from '../mock/mockJobsResponse';
import { AppliedJob } from '../models/applied-job.model';
import { JobSearchFilter } from '../models/job-search-filter.model';
import { Job } from '../models/job.model';
import { JobsQueryBody, JobsQueryResponse } from '../models/jobs-query.model';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  /* ----------------------------------- // ----------------------------------- */

  private _jobSearchResults: BehaviorSubject<Job[]> = new BehaviorSubject(null);
  public jobSearchResults$: Observable<Job[]> = this._jobSearchResults.asObservable().pipe(shareReplay());

  private _jobsQueryResponse: BehaviorSubject<JobsQueryResponse> = new BehaviorSubject(null);
  public jobsQueryResponse$: Observable<JobsQueryResponse> = this._jobsQueryResponse.asObservable().pipe(shareReplay());

  public searchKeyword: string;
  public searchLocation: { formattedAddress?: string; placeId?: string; coordinates: any };

  private _selectedJob: BehaviorSubject<Job> = new BehaviorSubject(null);
  public selectedJob$: Observable<Job> = this._selectedJob.asObservable().pipe(shareReplay());
  public jobIndex: number;
  public searchResultsLength: number; // TODO: unused? see BTP-100 PR
  public showMatchingJobs: boolean = false;

  private _appliedJobs: BehaviorSubject<AppliedJob[]> = new BehaviorSubject(null);
  public appliedJobs$: Observable<AppliedJob[]> = this._appliedJobs.asObservable();

  private _searchCleared: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public searchCleared$: Observable<boolean> = this._searchCleared.asObservable();

  /* ----------------------------- DISTANCE FILTER ---------------------------- */

  // TODO: distance filtering potentially needs redone, in both FE/BE. See Jira notes.
  public distanceFilter: JobSearchFilter = {
    filterName: 'Distance',
    singleSelect: true,
    hidden: true,
    filterOptions: [
      { label: '5 miles', value: 5, checked: false },
      { label: '10 miles', value: 10, checked: false },
      { label: '15 miles', value: 15, checked: false },
      { label: '25 miles', value: 25, checked: false },
      { label: '50 miles', value: 50, checked: false },
      { label: '100 miles', value: 100, checked: false },
      { label: 'Any Distance', value: null, checked: true },
    ],
  };
  public get distanceFilterValue(): number {
    return this.distanceFilter.filterOptions.find((o) => o.checked).value;
  }

  public set distanceFilterValue(v: number) {
    const optionFound = this.distanceFilter.filterOptions.find((o) => !o.checked && o.value === v);
    if (optionFound) {
      this.distanceFilter.filterOptions.forEach((o) => (o.checked = false));
      optionFound.checked = true;
    }
  }

  /* ---------------------------- CRITERIA FILTERS ---------------------------- */

  private defaultCriteriaFilters: JobSearchFilter[] = [
    { filterName: 'Employment Type', filterKey: 'employmentTypes', filterOptions: [], config: 'EnableJobSearchCriteriaEmploymentType' },
    { filterName: 'Category', filterKey: 'categories', filterOptions: [], config: 'EnableJobSearchCriteriaCategory' },
    { filterName: 'Industry', filterKey: 'industries', filterOptions: [], config: 'EnableJobSearchCriteriaIndustry' },
    { filterName: 'Specialty', filterKey: 'specialties', filterOptions: [], config: 'EnableJobSearchCriteriaSpecialty' },
    { filterName: 'Skill', filterKey: 'skills', filterOptions: [], config: 'EnableJobSearchCriteriaSkill' },
  ];

  private _jobSearchCriteriaFilters: BehaviorSubject<JobSearchFilter[]> = new BehaviorSubject(this.defaultCriteriaFilters);
  public jobSearchCriteriaFilters$: Observable<JobSearchFilter[]> = this._jobSearchCriteriaFilters.asObservable().pipe(shareReplay(1));

  /* ----------------------------- ENABLED FILTERS ---------------------------- */

  public enabledCriteriaFilters$: Observable<JobSearchFilter[]> = this.jobSearchCriteriaFilters$.pipe(
    map((filters) =>
      filters.reduce((acc, val) => {
        let filterOptionsCopy = [...val.filterOptions];
        filterOptionsCopy = filterOptionsCopy.filter((o) => o?.checked);
        if (filterOptionsCopy.length) acc.push({ ...val, filterOptions: filterOptionsCopy });
        return acc;
      }, []),
    ),
    shareReplay(1),
  );

  constructor(private http: HttpClient, private uxService: UxService, private sessionService: SessionService, private toastService: ToastService) {}

  requeryJobs(params: { pageStart?: number; filters?: JobSearchFilter[]; newSearch?: boolean }) {
    this.queryJobs({ pageStart: params.pageStart * 25, filters: params.filters }).subscribe();
  }

  queryJobs({
    pageStart = 0,
    filters = null,
    keyword = this.searchKeyword,
    coordinates = this.searchLocation?.coordinates,
    newSearch = false,
  }: {
    pageStart?: number;
    filters?: JobSearchFilter[];
    keyword?: string;
    coordinates?: any;
    newSearch?: boolean;
  }): Observable<JobsQueryResponse> {
    this.showMatchingJobs = false;
    const url = `${environment.applicant_core_api_host}/api/v1/JobPosting/query`;
    let body: Partial<JobsQueryBody> = { length: 25, start: pageStart };
    if (filters?.length) {
      filters.forEach((f) => (body[f.filterKey] = f?.filterOptions?.filter((o) => o.checked).map((o) => o.value)));
    }
    if (coordinates) {
      body.latitude = coordinates.latitude;
      body.longitude = coordinates.longitude;
    }
    if (keyword) {
      // search 'name' and 'description' for keyword
      body.keyword = keyword;
    }
    body.distance = this.distanceFilterValue || 250;

    this.uxService.loading();
    return this.http.post<JobsQueryResponse>(url, body).pipe(
      tap((jobResponse) => {
        this.distanceFilter = { ...this.distanceFilter, hidden: !coordinates?.latitude || !coordinates?.longitude };
        this._jobsQueryResponse.next(jobResponse);
        if (newSearch) this._jobSearchCriteriaFilters.next(this.setFilterCriteria(jobResponse));
        this._jobSearchResults.next(jobResponse.data ? jobResponse.data : []);
        this.searchResultsLength = jobResponse.data?.length;
        if (this._selectedJob.getValue()) {
          const selectedJobFound = jobResponse.data?.find((j) => j.jobId === this._selectedJob.getValue()?.jobId);
          if (!selectedJobFound)
            // if selected job is not found in new search results, clear selectedJob
            this._selectedJob.next(null);
        }
      }),
      catchError((err) => this.handleError(err)),
      finalize(() => this.uxService.loading(false)),
    );
  }

  getJobDetails(jobId: string, index?: number): Observable<Job> {
    const jobFromSearchResults = this._jobSearchResults.value?.find((job) => job.jobId == jobId);
    console.debug(jobFromSearchResults);
    if (jobFromSearchResults) {
      this.jobIndex = index ?? jobFromSearchResults.index;
      this._selectedJob.next({ ...jobFromSearchResults, index: this.jobIndex });
      return of(jobFromSearchResults);
    } else {
      const url = `${environment.applicant_core_api_host}/api/v1/JobPosting/${jobId}`;
      this.uxService.loading();
      return this.http.get<Job>(url).pipe(
        tap((jobDetails) => {
          this.jobIndex = index ?? undefined;
          this._selectedJob.next({ ...jobDetails, index: this.jobIndex });
        }),
        catchError((err) => this.handleError(err)),
        finalize(() => this.uxService.loading(false)),
      );
    }
  }

  jobIdByIndex(indexChange: number) {
    return this._jobSearchResults.getValue()[this.jobIndex + indexChange]?.jobId;
  }

  clearSelectedJob(): void {
    this._selectedJob.next(null);
  }

  clearJobSearch(): void {
    this._searchCleared.next(true);
    this._jobSearchResults.next(null);
    this._selectedJob.next(null);
    this.showMatchingJobs = false;
  }

  handleError(err: any) {
    // TODO: BTP -- handle/log errors to server
    console.error(err);
    // TODO: BTP -- need exact error message text
    this.toastService.error('Something went wrong', 'Jobs Service error message.');
    return throwError(() => err);
  }

  /* ----------------------------------- // ----------------------------------- */

  getMatchingJobs(): Observable<Job[]> {
    this.showMatchingJobs = true;
    const url = `${environment.applicant_core_api_host}/api/v1/integrations/ats/bullhorn/suggestedJobs`;
    this.uxService.componentLevelLoading();
    return this.http.get<Job[]>(url).pipe(
      tap((matchingJobs) => {
        this._jobsQueryResponse.next({ data: matchingJobs, totalCount: matchingJobs.length });
        this.searchResultsLength = matchingJobs.length;
        matchingJobs.forEach((job, index) => (job.index = index));
        this._jobSearchResults.next(matchingJobs);
      }),
      catchError((err) => this.handleError(err)),
      finalize(() => this.uxService.componentLevelLoading(false)),
    );
  }

  /* ----------------------------------- // ----------------------------------- */

  applyToJob(jobId: string): Observable<BtpProgressStatus> {
    const url = `${environment.applicant_core_api_host}/api/v1/integrations/ats/bullhorn/JobSubmissions/${jobId}`;
    return this.http.post<any>(url, {}).pipe(
      startWith({ pending: true, status: 'Applying...' }),
      map((response) => (!('pending' in response) ? { pending: false, status: 'Applied' } : response)),
      catchError((err) => {
        this.toastService.error('Something went wrong', `Your job application could not be entered. Please get in touch if the error persists.`);
        return of(null);
      }),
      tap((result) => {
        console.debug(result);
        if (result?.status === 'Applied') this.toastService.success('Success!', `Applied to job, ${this._selectedJob.getValue().name}`);
      }),
      shareReplay(),
    );
  }

  getAppliedJobs(): Observable<AppliedJob[]> {
    const url = `${environment.applicant_core_api_host}/api/v1/integrations/ats/bullhorn/jobsubmissions/`;
    this.uxService.loading();
    return this.http.get<AppliedJob[]>(url).pipe(
      tap((jobSubmissionDetails) => {
        this._appliedJobs.next(jobSubmissionDetails);
      }),
      catchError((err) => this.handleError(err)),
      finalize(() => this.uxService.loading(false)),
    );
  }

  /* ----------------------------------- // ----------------------------------- */
  // TODO: BTP -- Eventually Remove; just for local testing

  setupMockData() {
    console.debug(`jobsservice > mockData`);
    this._jobSearchResults.next(mockJobResponse.data);
    this.setFilterCriteria(mockJobResponse);
  }

  clearMockData() {
    this._jobSearchResults.next([]);
  }

  /* ----------------------------------- // ----------------------------------- */

  setFilterCriteria(jobResponse: JobsQueryResponse): JobSearchFilter[] {
    const filterData = jobResponse.filterData;
    const filtersCopy = [...this._jobSearchCriteriaFilters.getValue()];

    // UPDATE FILTER OPTIONS
    if (filterData) {
      filtersCopy.forEach((f) => {
        this.setCriteriaFilterOptions(f, filterData[f.filterKey]);
      });
      filtersCopy.sort((a, b) => {
        if (a?.filterOptions?.length < 1) return 1;
        if (b?.filterOptions?.length < 1) return -1;
        return 0;
      });
    } else {
      filtersCopy.forEach((f) => (f.filterOptions = []));
    }
    return filtersCopy;
  }

  private setCriteriaFilterOptions(existingFilter: JobSearchFilter, jobResultFilterOptions: string[]): void {
    if (!jobResultFilterOptions?.length) return;
    // PRESERVE ALREADY ENABLED FILTER OPTIONS
    existingFilter.filterOptions = jobResultFilterOptions.map((filterValue) => ({
      label: filterValue,
      value: filterValue,
      checked: existingFilter.filterOptions?.find((x) => x.label === filterValue)?.checked || false,
    }));
  }

  resetFilters(): void {
    const filtersCopy = [...this._jobSearchCriteriaFilters.getValue()];
    filtersCopy.forEach((f) => f.filterOptions?.forEach((option) => (option.checked = false)));
    this._jobSearchCriteriaFilters.next(filtersCopy);
  }

  updateFilters(modifiedFilters: JobSearchFilter[]): void {
    const filtersCopy = [...this._jobSearchCriteriaFilters.getValue()];
    modifiedFilters?.forEach((modFilter) => {
      const filter = filtersCopy.find((f) => f.filterName === modFilter.filterName);
      if (filter) filter.filterOptions = modFilter.filterOptions;
    });
    this._jobSearchCriteriaFilters.next(filtersCopy);
  }
}
